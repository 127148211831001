import React, { useContext } from 'react';
import { AuthContext } from "../../contexts/AuthContext";
import Navbar from '../../components/Navbar/Navbar';
import { Waves } from '../LandingPage/Waves';
import { Ship } from '../LandingPage/Ship';
import { useNavigate } from 'react-router-dom';
import { Construction } from 'lucide-react';

const Dashboard = () => {
  const { currentUser, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = async () => {
    await logout();
    navigate('/signin');
  };

  if (!currentUser) {
    navigate('/signin');
    return null;
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white">
      <Navbar />
      <div className="relative z-10">
        <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 lg:px-8 text-center">
          <div className="bg-white/80 backdrop-blur-sm rounded-2xl p-8 shadow-xl">
            <Construction className="w-16 h-16 text-blue-600 mx-auto mb-6" />
            <h1 className="text-4xl font-bold text-gray-900 mb-4">
              Coming Soon!
            </h1>
            <p className="text-xl text-gray-600 mb-8">
              We're working hard to build an amazing mentorship experience for you. 
              The dashboard is currently under construction and will be available soon.
            </p>
            <p className="text-gray-500">
              Thank you for your patience and understanding.
            </p>
          </div>
        </div>
      </div>
      <div className="fixed bottom-0 left-0 right-0 z-0">
        <Waves />
      </div>
      <div className="fixed bottom-20 left-1/2 transform -translate-x-1/2 z-0">
        <Ship className="w-32 h-32" />
      </div>
    </div>
  );
};

export default Dashboard;
